<template>
  <v-container>
    <v-skeleton-loader
      type="table"
      v-if="!data_loaded"
      class="mt-10"
    ></v-skeleton-loader>
    <!--/ sekeletion -->

    <v-card elevation="0" class="mt-10" v-if="data_loaded">
      <v-card-title class="font-weight-bold">
        <h4>مديري الديلفيري</h4>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = true" color="primary">اضافة</v-btn>
      </v-card-title>
      <v-data-table
        :footer-props="$store.state.footer_props"
        :items="managers"
        :headers="headers"
        :mobile-breakpoint="0"
      >
        <template v-slot:[`item.address`]="{ item }">
          {{ item.address || "__" }}
        </template>



        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="edit_manager(item)" small icon>
            <v-icon small>edit</v-icon>
          </v-btn>

          <v-btn @click="get_delete_id(item.id)" small icon>
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
        <!--/ actions -->
      </v-data-table>
      <!--/ datatable -->
    </v-card>
    <!--/ card -->

    <v-dialog persistent max-width="400" v-model="dialog">
      <v-card>
        <v-card-title>
          {{ dialog_title }}
        </v-card-title>
        <!--/ title -->
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="manager.manager_name"
              :rules="[(v) => !!v || 'حقل مطلوب']"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                اسم المدير
              </template>
            </v-text-field>
            <v-text-field
              v-model="manager.manager_phone"
              :rules="[(v) => !!v || 'حقل مطلوب']"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                هاتف المدير
              </template>
            </v-text-field>
            <v-text-field
              v-model="manager.address"
            >
              <template slot="label">
                 عنوان المدير
              </template>
            </v-text-field>
          </v-form>
          <!--/ form -->
        </v-card-text>
        <!--/text -->
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="save_loader"
            :loading="save_loader"
            color="primary"
            small
            @click="save()"
            >حفظ
          </v-btn>
          <v-btn
            :disabled="save_loader"
            @click="close_dialog()"
            color="error"
            text
            small
          >
            الغاء</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ Dialog -->

    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حذف المدير</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"> حذف المدير ؟ </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_manager()"
            depressed
            color="error"
            >حذف</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
  <!--/ container -->
</template>

<script>
export default {
  name: "managers",

  data() {
    return {
      manager: {
        manager_name: "",
        manager_phone: "",
        address:"",
        id:null,
      },
      dialog: false,
      snackbar_success: false,
      delete_dialog: false,
      delete_loader: false,
      delete_id: null,
      data_loaded: false,
      managers: [],
      save_loader: false,
      headers: [
        {
          value: "manager_name",
          text: "اسم المدير",
          align: "center",
        },
        {
          value: "manager_phone",
          text: "رقم الهاتف",
          align: "center",
        },
        {
          value: "address",
          text: "العنوان",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
      dialog: false,
    };
  },

  watch: {},
  computed: {
    dialog_title() {
      return this.manager.id != null ? "تعديل المدير" : "اضافة مدير";
    },
    def_lang() {
      let def_lang = this.langs.find((e) => e.is_default);
      return def_lang;
    },
  },
  methods: {
    get_managers() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "managers",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.managers = Object.assign([], res.data.data);
        })
    },
    get_delete_id(id) {
      this.delete_id = id;
      this.delete_dialog = true;
    },

    delete_manager() {
      if (!this.delete_loader) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `managers/${this.delete_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.get_managers();
            this.delete_loader = false;
            this.delete_dialog = false;
            this.snackbar_success = true;
          })
      }
    },

    edit_manager(item) {
      this.manager.manager_name = item.manager_name;
      this.manager.manager_phone = item.manager_phone;
      this.manager.address = item.address;
      this.manager.id = item.id;      
      this.dialog = true;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("public__request", {
            config: {
              url: this.manager.id
                ? `managers/${this.manager.id}`
                : `managers`,
              method: this.manager.id ? 'put' : "post",
            },
            data: this.manager,
          })
          .then((res) => {
            this.save_loader = false;
            this.close_dialog();
            this.get_managers();
          })
      }
    },
    close_dialog() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.manager.id = null;
    },
  },
  mounted() {
    this.get_managers();
  },
};
</script>
